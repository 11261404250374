import React from 'react'
import ReactDOM from 'react-dom'
import CultureBlog from '../_culture-blog'
import $ from 'jquery'

export default {

    init() {

        ReactDOM.render(

            <CultureBlog 
                propQuery=""
                excludeCategories = {[104, 1, 151, 154, 150]}
                excludeCatStr = '&categories_exclude=104,1,151,154,150'
            />,
            document.getElementById("blogroot")

        )

    },

    finalize() {
      
        let $sidebar = $('.sidebar.col-md-3')
        let $subscribe = $('section#custom_html-2')
        let $searchForm = $('.sidebar form#searchform')
        let $offers = $('section#offers_widget-4')
        $sidebar.prepend($subscribe)
        //$sidebar.prepend($searchForm)
        $sidebar.append($offers)

    },

  }