import Router from './util/Router'
import common from './routes/common'
import pageTemplateTemplateNewBlog from './routes/pageTemplateTemplateNewBlog'
import pageTemplateTemplateOfficeFun from './routes/pageTemplateTemplateOfficeFun'
import pageTemplateTemplateCultureGrid from './routes/pageTemplateTemplateCultureGrid'
import home from './routes/home'
import '../styles/main.scss'

const routes = new Router({

    common,
    pageTemplateTemplateNewBlog,
    pageTemplateTemplateOfficeFun,
    pageTemplateTemplateCultureGrid,
    home,

})

document.addEventListener('DOMContentLoaded', () => { routes.loadEvents() })