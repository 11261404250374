import * as $ from 'jquery'

export default {

    init() {

        let $h5 = $('.team-box:nth-child(3) h5');
        const h5Html = $h5.html()
        $h5.html(`<a href="/about-us/news">${h5Html}</a>`)

    },

    finalize() {
      
        

    },

  }